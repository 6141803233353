import axios from 'axios'
import {
  Message,
  MessageBox
} from 'element-ui'
import {
  removeAuth
} from '@/utils/auth'
import qs from 'qs'
import { debounce } from 'throttle-debounce'
import router from '../router'

/**
 * 检查dom是否忽略
 * @param {*} e
 */
const clearCacheEnterLogin = debounce(500, () => {
  removeAuth().then(() => {
    location.reload() // 为了重新实例化vue-router对象 避免bug
  }).catch(() => {
    location.reload()
  })
})

const errorMessage = debounce(500, (message, type = 'error') => {
  Message({
    message: message,
    duration: 1500,
    type: type
  })
})

const confirmMessage = debounce(1000, (message) => {
  MessageBox.confirm(message, '提示', {
    confirmButtonText: '确定',
    showCancelButton: false,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    showClose: false,
    type: 'warning'
  }).then(() => {
    if ((window.app.$route && window.app.$route.name !== 'login') || !window.app.$route) {
      clearCacheEnterLogin()
    }
  }).catch(() => {
  })
})

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 600000 // 请求超时时间
})

// 添加请求拦截器
axios.interceptors.request.use(request => {
  // store.commit("UPDATE_Loading", true);
  return request
}, error => {
  return Promise.reject(error);
})
// 添加响应拦截器
axios.interceptors.response.use(response => {
  // store.commit("UPDATE_Loading", false);
  // if (response.data.code === 2001206 || response.data.code === 2001207) {
  // 	//登录过期
  // 	location.href = '/login'
  // } else if (response.data.code === 2001208) {
  // 	// 请先验证邮箱
  // 	location.href = "/verification"
  // }

  return response
}, error => {
  return Promise.reject(error);
});

export default service
