import request from '@/utils/request'

export function getNewest(param) {
    return request({
      url: '/upgradecenter/main/getNewest',
      method: 'get',
      params: param,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
  }

export function getLibListBySn(param) {
    return request({
        url: '/upgradecenter/match/getLibListBySn',
        method: 'get',
        params: param,
        headers: {
        'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

export function getLibList(param) {
    return request({
        url: '/upgradecenter/main/getLibList',
        method: 'get',
        params: param,
        headers: {
        'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

export function getLibById(data) {
    return request({
        url: '/upgradecenter/main/getLibById',
        method: 'post',
        data: data,
        headers: {
        'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

export function getProduct(param) {
    return request({
        url: '/upgradecenter/main/getProduct',
        method: 'get',
        params: param,
        headers: {
        'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}