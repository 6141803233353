<template>
  <el-container>
    <el-header>
      <div class="logo" v-if="logoTitle == '启明星辰'">
        <b>
          <img src="./images/logo.png" alt="启明星辰" />
        </b>
        <span>| 统一升级中心</span>
      </div>
      <div class="logo" v-else>
        <b>{{ logoTitle }}</b>
        <span>| 统一升级中心</span>
      </div>
      <div class="searchWht">
        <el-input placeholder="请输入内容" v-model="searcherTxt" class="input-with-select"
          @keyup.enter.native="getLibListBySn">
          <el-select v-model="checkIndex" :popper-append-to-body="false" popper-class="select-popper" slot="prepend"
            placeholder="请选择">
            <el-option label="SN" value="1"></el-option>
            <el-option label="项目编号" value="2"></el-option>
          </el-select>
          <span slot="append" class="el-icon-search" @click="getLibListBySn"></span>
        </el-input>
      </div>
    </el-header>
    <el-container>
      <el-aside>
        <!-- 左侧一二级目录 -->
        <dl class="EditionLeft" v-for="(item, index) in productList" :key="index">
          <dt>{{ item.firstProductName }}</dt>
          <dd v-for="(secon, id) in item.secondProduct" :key="id"
            :class="secon.secondProductId == libIdData.modelId ? 'hover' : ''"
            @click="secondProductData(secon.secondProductId, item.firstProductName, secon.secondProductName)">
            {{ secon.secondProductName }}</dd>
        </dl>
      </el-aside>
      <el-main>
        <div class="EditionRight">
          <ul class="tabTop" :class="[thirdProductList.length > 3 ? 'maxLens' : '']">
            <!-- 右侧上面三级目录 -->
            <li v-for="(third, pro) in thirdProductList" :key="pro"
              :class="third.thirdProductId == libIdData.thirdId ? 'hover' : ''"
              @click="thirdProducData(third.thirdProductId, third.thirdProductName)" :title="third.thirdProductName">
              {{ third.thirdProductName }}</li>
          </ul>
          <div class="downTags">
            <ul>
              <!-- 右侧四级目录 -->
              <li :class="item.fourthProductId == libIdData.fourthId ? 'hover' : ''"
                v-for="(item, index) in fourthProductList" :key="index"
                @click="clickGetPackages(item.fourthProductId, item.fourthProductName)" :title="item.fourthProductName">
                {{ item.fourthProductName }}</li>
            </ul>
          </div>
          <div class="breadcrumb">
            <!-- 面包屑 -->
            <span>当前位置：{{ mbx.one }}</span> >
            <span>{{ mbx.two }}</span> >
            <span>{{ mbx.thre }}</span> >
            <b>{{ mbx.four }}</b>
          </div>
          <div class="logicList">
            <!-- 数据列表 -->
            <div class="list" :class="[downOff == indexy ? 'hover' : '']" v-for="(item, indexy) in packagesList" :key="indexy">
              <div class="logTop">
                <dl>
                  <dt>
                    <span>{{ item.packageName }}</span>
                    <el-tag size="mini" style="margin-left: 15px;" v-if="item.isFullUpgrade != -1">
                      <template v-if="item.isFullUpgrade == 0"> 增量升级 </template>
                      <template v-else-if="item.isFullUpgrade == 1">全量升级</template>
                    </el-tag>
                  </dt>
                  <dd>版本：{{ item.version }} | 大小：{{ item.size }} | Md5：{{ item.md5 }}</dd>
                </dl>
                <div class="lRigt">
                  <i>{{ item.releaseTime }}</i>
                  <a :href="item.downloadLink" title="升级包下载">
                    <span>下载</span>
                  </a>
                </div>
              </div>
              <div class="logCon">
                <dl class="explain">
                  <dt>说明：</dt>
                  <dd>{{ item.description }}</dd>
                </dl>
                <div class="update">
                  <div class="title">更新：</div>
                  <div class="content">
                    <div class="newly" v-show="item.updateContent.add">
                      <div class="ntit">新增</div>
                      <div class="con">
                        <dl v-for="(add, ind) in item.updateContent.add" :key="ind">
                          <dt>{{ add.content }}</dt>
                          <dd>
                            <span v-for="(t, g) in add.tag" :key="g">{{ t }}</span>
                          </dd>
                        </dl>
                      </div>
                    </div>
                    <div class="modify" v-show="item.updateContent.change">
                      <div class="ntit">修改</div>
                      <div class="con">
                        <dl v-for="(nge, c) in item.updateContent.change" :key="c">
                          <dt>{{ nge.content }}</dt>
                          <dd>
                            <span v-for="(n, h) in nge.tag" :key="h">{{ n }}</span>
                          </dd>
                        </dl>
                      </div>
                    </div>
                    <div class="modifyDel" v-show="item.updateContent.delete">
                      <div class="ntit">删除</div>
                      <div class="con">
                        <dl v-for="(del, te) in item.updateContent.delete" :key="te">
                          <dt>{{ del.content }}</dt>
                          <dd>
                            <span v-for="(m, x) in del.tag" :key="x">{{ m }}</span>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <dl class="explain">
                  <dt>注意：</dt>
                  <dd>
                    <p v-for="(att, ion) in item.attention" :key="ion">{{ att }}</p>
                  </dd>
                </dl>
                <span class="clickBtn" @click="downSlides(indexy)">{{ downOff == indexy ? '收起' : '更多' }}</span>
              </div>
            </div>
          </div>

          <div class="loadingBottom" v-show="pageOffs">
            <img src="./images/loding.gif" alt /> 正在加载数据……
          </div>
        </div>
        <div class="useTop" v-show="upWsy" @click="getupTops">回顶部</div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import { getProduct, getPackages, getFourthProduct } from '../../api/main';

export default {
  data () {
    return {
      logoTitle: "启明星辰",
      companyId: 1,
      checkIndex: "1",
      searcherTxt: "",
      upWsy: false,
      productOFFs: false,
      libIdData: {
        modelId: 1,
        thirdId: 0,
        fourthId: 0,
        packgId: 0,
        productId: 0,
        page: 1,
        pageSize: 10
      },
      tabsList: [],
      downTabs: [],
      wuOff: false,
      productList: [],
      thirdProductList: [],
      fourthProductList: [],
      packagesList: [
        {
          "packageName": "天阗入侵检测与管理系统（IDSNT系列）事件库(20230819)",
          "version": "20230819",
          "size": "11.8 MB",
          "md5": "8fbf182a1be515be6d2eeef775de45df",
          "downloadLink": "https://venuscloud.cn/download/zip/CS-TDS-IDSNT_EVENT/20230819213611.zip",
          "releaseTime": "2023-08-19 21:36:05",
          "title": "天阗入侵检测与管理系统（IDSNT系列）事件库(20230819)",
          "description": "该升级包为全量升级包，升级后硬件版本和引擎版本不变。",
          "isFullUpgrade": 1,
          "updateContent": {
            "add": [
              {
                "tag": [
                  "高级事件"
                ],
                "content": "HTTP_安全漏洞_科荣AIO管理系统UtilServlet文件读取漏洞"
              },
              {
                "tag": [
                  "高级事件"
                ],
                "content": "HTTP_安全漏洞_大华车载系统任意文件上传漏洞"
              },
              {
                "tag": [
                  "高级事件"
                ],
                "content": "HTTP_安全漏洞_禅道18.0-18.3_backstage命令注入漏洞"
              }
            ],
            "change": [
              {
                "tag": [
                  "高级事件"
                ],
                "content": "HTTP_安全漏洞_致远OA_fileToExcelManager文件上传"
              }
            ]
          },
          "attention": []
        },
      ],
      mbx: {
        one: "",
        two: "",
        thre: "",
        four: ""
      },
      isTop: true,
      Special: true,
      pageCount: 0,
      pageOffs: false,
      downOff: -1,
      fourthProductId: 1,
      thirdCancel: null,
      fourthCancel: null,
      packageCancel: null,
    };
  },
  mounted () {
    this.handleScroll();
  },
  created () {
    this.setHost();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    setHost () {
      var hostHs = location.hostname;
      const testA = /(10.51.15.214)/i;

      if (testA.test(window.location.host)) {
        this.getProduct(1);
      } else {
        if (
          hostHs.indexOf("venustech.download.venuscloud.cn") != -1 ||
          hostHs.indexOf("localhost") != -1
        ) {
          // if (hostHs.indexOf('devupgrade.venuscloud.cn') != -1 || hostHs.indexOf('localhost') != -1) {
          this.getProduct(1);
          this.companyId = 1;
          this.logoTitle = "启明星辰";
        } else {
          this.companyId = 2;
          this.getProduct(2);
          this.logoTitle = "网御星云";
        }
      }
    },
    getupTops () {
      var that = this;
      this.scrollToptimer = setInterval(function () {
        //获取滚动条距离顶部高度
        var osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var ispeed = Math.floor(-osTop / 7);

        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;

        if (osTop == 0) {
          //到达顶部，清除定时器
          clearInterval(that.scrollToptimer);
        }
        that.isTop = true;
      }, 30);
    },
    handleScroll () {
      //scroll事件
      //滚动条在y轴上的滚动距离
      var that = this;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var windowHeitht =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop >= windowHeitht) {
        //是否滚动到底部的判断
        this.upWsy = true;
      } else {
        this.upWsy = false;
      }
      if (!that.isTop) {
        clearInterval(that.scrollToptimer);
      }

      setTimeout(function () {
        if (scrollTop > 170) {
          that.headOn = false;
        } else {
          that.headOn = true;
        }
      }, 350);
      if (that.timer) {
        clearTimeout(that.timer);
      }
      if (that.pageCount > 0 && that.Special) {
        that.timer = setTimeout(function () {
          that.getSpecialData();
          that.timer = null;
        }, 350);
      }
      if (scrollTop + windowHeitht >= scrollHeight - 50) {
        that.Special = true;
      } else {
        that.Special = false;
      }
      that.isTop = false;
    },
    downSlides (numb) {
      //下载列表的点击事件
      if (this.downOff != numb) {
        this.downOff = numb;
      } else if (this.downOff == numb) {
        this.downOff = -1;
      }
    },
    getProduct (e) {
      //根据公司id获取一级目录+二级目录
      let param = {
        companyId: e
      }
      getProduct(param).then(res => {
        if (res.data.code === 200) {
          // console.log(res.data.data)
          this.productList = res.data.data;
          this.libIdData.modelId = this.productList[0].secondProduct[0].secondProductId
          this.mbx.one = this.productList[0].firstProductName;
          this.mbx.two = this.productList[0].secondProduct[0].secondProductName;
          this.secondProductData(
            this.productList[0].secondProduct[0].secondProductId,
            this.productList[0].firstProductName,
            this.productList[0].secondProduct[0].secondProductName
          );
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    secondProductData (id, one, two) {
      let self = this
      this.thirdCancel && this.thirdCancel('取消')
      //根据二级目录id获取三级目录
      if (this.libIdData.modelId != id) {
        this.libIdData.modelId = id;
        this.thirdProductList = []
        this.fourthProductList = []
        this.packagesList = []
      }
      if (id == 25) {
        window.open("http://www.venuseye.com.cn/download/", "top");
        return false;
      }
      this.mbx.one = one;
      this.mbx.two = two;
      this.mbx.thre = ""
      this.mbx.four = ""
      this.axios
        .get("/upgradecenter/main/getThirdProduct", {
          params: {
            secondProductId: this.libIdData.modelId
          },
          cancelToken: new self.axios.CancelToken(function executor (c) {
            self.thirdCancel = c
            // 这个参数 c 就是CancelToken构造函数里面自带的取消请求的函数，这里把该函数当参数用
          })
        })
        .then(res => {
          if (res.data.code === 200) {
            this.thirdProductList = res.data.data;
            this.libIdData.thirdId = this.thirdProductList[0].thirdProductId
            this.mbx.thre = this.thirdProductList[0].thirdProductName
            this.thirdProducData(
              this.thirdProductList[0].thirdProductId,
              this.thirdProductList[0].thirdProductName
            );
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    thirdProducData (id, thre) {
      let self = this
      this.fourthCancel && this.fourthCancel('取消')
      //根据三级目录id获取四级目录信息
      if (this.libIdData.thirdId != id) {
        this.libIdData.thirdId = id;
        this.fourthProductList = []
        this.packagesList = []
      }
      this.fourthProductId = id;
      this.mbx.thre = thre
      this.mbx.four = ""
      this.axios.get("/upgradecenter/main/getFourthProduct", {
        params: {
          thirdProductId: this.libIdData.thirdId
        },
        cancelToken: new self.axios.CancelToken(function executor (c) {
          self.fourthCancel = c
          // 这个参数 c 就是CancelToken构造函数里面自带的取消请求的函数，这里把该函数当参数用
        })
      })
        .then(res => {
          if (res.data.code === 200) {
            this.fourthProductList = res.data.data;
            this.libIdData.page = 1;
            if (this.fourthProductList.length > 0) {
              this.libIdData.fourthId = this.fourthProductList[0].fourthProductId
              this.mbx.four = this.fourthProductList[0].fourthProductName
              this.getPackages(
                this.fourthProductList[0].fourthProductId,
                this.fourthProductList[0].fourthProductName
              );
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    clickGetPackages (id, four) {
      //根据四级目录id获取相应升级包信息
      this.libIdData.page = 1;
      this.getPackages(id, four);
    },
    getPackages (id, four) {
      let self = this
      this.packageCancel && this.packageCancel('取消')
      //根据四级目录id获取相应升级包信息
      if (this.libIdData.fourthId != id) {
        this.libIdData.fourthId = id;
        this.packagesList = [];
      }
      this.fourthProductId = id;
      this.mbx.four = four;
      this.pageOffs = true;
      this.axios.get("/upgradecenter/main/getPackages", {
        params: {
          fourthProductId: this.libIdData.fourthId,
          page: this.libIdData.page,
          pageSize: this.libIdData.pageSize
        },
        cancelToken: new self.axios.CancelToken(function executor (c) {
          self.packageCancel = c
          // 这个参数 c 就是CancelToken构造函数里面自带的取消请求的函数，这里把该函数当参数用
        })
      })
        .then(res => {
          if (res.data.code === 200) {
            if (this.libIdData.page === 1) {
              //   console.log("首页");
              //   console.log(this.packagesList);
              //   console.log(res.data.data);
              this.packagesList = res.data.data;
              this.pageCount = res.data.pageCount;
            } else {
              //   console.log("非首页");
              //   console.log(this.packagesList);
              //   console.log(res.data.data);
              res.data.data.forEach(item => {
                this.packagesList.push(item);
              });
            }
            this.pageOffs = false;
          } else {
            this.$message.error(res.data.msg);
            this.pageOffs = true;
          }
        });
    },
    getLibListBySn () {
      //根据sn查询包含类库及其数量
      if (this.searcherTxt == "") {
        this.$message.error("搜索内容不能为空！");
        return false;
      }
      if (this.snOff === 2) {
        window.open(
          "https://itemupgrade.venuscloud.cn/synchronization/" +
          this.searcherTxt
        );
        return false;
      }
      this.axios
        .get("/upgradecenter/match/getLibListBySn", {
          params: {
            sn: this.searcherTxt,
            companyId: this.companyId
          }
        })
        .then(res => {
          if (res.data.code === 200) {
            this.libIdData.productId = res.data.fourthProductId; //4级
            this.libIdData.modelId = res.data.secondProductId; //2级
            this.libIdData.thirdId = res.data.thirdProductId; //3级
            this.secondProductData(this.libIdData.modelId, res.data.firstProductName, res.data.secondProductName);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getSpecialData () {
      // console.log(this.pageCount,this.fourthProductId)
      if (this.pageCount > this.libIdData.page) {
        ++this.libIdData.page;
        this.getPackages(this.fourthProductId, this.mbx.thre);
      }
    }
  },
  beforeDestroy () {
    window.addEventListener("scroll", this.handleScroll, true);
  }, //生命周期 - 销毁之前
  destroyed () {
    window.removeEventListener("scroll", this.handleScroll, false);
  } //生命周期 - 销毁完成
};
</script>
<style lang="scss">
.el-input--suffix .el-input__inner {
  width: 100px;
  background: #494e59;
}

.el-input__inner,
.el-button {
  background-color: #3d424e;
  border: none;
  color: #fff;
}

.el-input-group__append,
.el-input-group__prepend {
  background-color: #494e59;
  color: #fff;
  border: 0;
}

.el-input-group__append {
  background: #3d424e;
  cursor: pointer;
  font-size: 20px;
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #c0c4cc;
}

html,
body {
  height: 100%;
  background-color: #f5f7fa;
  font-family: PingFangSC-Medium, PingFang SC;
}
</style>
<style lang="scss" scoped>
@font-face {
  font-family: "iconfont";
  /* Project id 1567678 */
  src: url("images/iconfont.woff2?t=1620460470738") format("woff2"),
    url("images/iconfont.woff?t=1620460470738") format("woff"),
    url("images/iconfont.ttf?t=1620460470738") format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.breadcrumb {
  padding: 15px 0 20px 25px;
  margin-left: 20px;
  position: relative;
  font-size: 14px;
  color: #606266;

  &::before {
    font-family: "iconfont";
    content: "\e6c6";
    position: absolute;
    left: 4px;
    top: 16px;
    font-size: 16px;
  }
}

.searchWht {
  width: 480px;

  .select-popper {
    .el-select-dropdown__item.selected {
      color: #333;
    }
  }
}

.el-header {
  background-color: rgba(40, 45, 58, 1);
  color: rgba(255, 255, 255, 1);
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  min-width: 1366px;

  .logo {
    width: 300px;
    height: 60px;
    line-height: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;

    b {
      font-size: 28px;
      color: white;
      vertical-align: middle;
      line-height: 50px;
      margin-top: 8px;
    }

    span {
      font-size: 24px;
      color: white;
    }
  }
}

.el-aside {
  color: #333;
  min-height: 100%;
}

.el-main {
  min-width: 1000px;
  background-color: #fff;
  color: #333;
  padding: 0;
  height: 100%;
  min-height: 800px;
}

body>.el-container {
  margin-bottom: 40px;
}

.useTop {
  width: 54px;
  height: 54px;
  border-radius: 6px;
  background: white;
  position: fixed;
  bottom: 30px;
  right: 5px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  color: rgba(144, 147, 153, 1);
  border: 1px solid #e4e7ed;
  cursor: pointer;

  /* Opera */
  &::before {
    font-family: "iconfont";
    content: "\e6c3";
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    width: 100%;
    padding-top: 5px;
    line-height: 20px;
    display: block;
  }
}

.EditionLeft {
  width: 300px;
  padding: 20px 0 0 20px;

  dt {
    font-size: 16px;
    color: rgba(144, 147, 153, 1);
    line-height: 44px;
  }

  dd {
    font-size: 14px;
    line-height: 35px;
    padding-left: 16px;
    width: 100%;
    cursor: pointer;
    border-left: 4px solid #f5f7fa;

    &:hover,
    &.hover {
      background: #e3f0ff;
      color: rgba(64, 158, 255, 1);
      border-left: 4px solid #409eff;
      font-weight: bold;
    }
  }
}

.EditionRight {
  .tabTop {
    width: 100%;
    height: 70px;
    line-height: 50px;
    padding-top: 20px;
    background-color: #f5f7fa;
    overflow-x: auto;
    white-space: nowrap;

    li {
      color: #303133;
      font-size: 14px;
      padding: 0 40px 0 20px;
      height: 50px;
      line-height: 50px;
      display: inline-block;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.hover {
        background: #fff;
        font-weight: bold;
        position: relative;
        max-width: 480px;

        &::after {
          content: "";
          position: absolute;
          right: -25px;
          top: 0;
          width: 0px;
          height: 0px;
          border-left: 25px solid transparent;
          border-right: 25px solid transparent;
          border-top: 50px solid #f5f7fa;
        }
      }
    }
  }

  .maxLens {
    li {
      max-width: 160px;
    }
  }

  .downTags {
    padding-top: 40px;
    font-size: 14px;
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    margin: 0 64px 0 20px;

    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 20px;
      padding-bottom: 19px;

      li {
        min-width: 90px;
        text-align: center;
        cursor: pointer;
        position: relative;
        padding: 0 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 280px;

        span {
          font-size: 16px;
          color: rgba(153, 153, 153, 1);
        }

        &.hover,
        &:hover {
          color: rgba(39, 118, 218, 1);

          span {
            color: rgba(39, 118, 218, 1);
          }
        }

        &.hover {
          &::after {
            content: "";
            width: 30px;
            position: absolute;
            left: 50%;
            margin-left: -13px;
            bottom: -20px;
            border-top: 2px solid rgba(39, 118, 218, 1);
          }
        }
      }
    }
  }
}

.logicList {
  padding: 0 64px 20px 20px;

  .logTop {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;

    dl {
      width: 66%;

      dt {
        font-size: 18px;
        font-weight: 500;
        color: #303133;
        line-height: 25px;
      }

      dd {
        padding-top: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #909399;
        line-height: 20px;
      }
    }

    .lRigt {
      line-height: 40px;
      width: 34%;
      display: flex;
      justify-content: space-around;

      &::before {
        // font-family: 'iconfont';
        content: "发布时间:";
        font-size: 14px;
        color: rgba(0, 0, 0, 0.3);
        line-height: 42px;
        white-space: nowrap;
      }

      i {
        width: 204px;
        padding: 0 10px;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        font-style: normal;
        line-height: 42px;
      }

      span {
        width: 83px;
        height: 40px;
        background: #ffffff;
        border-radius: 4px;
        display: inline-block;
        border: 1px solid #409eff;
        color: rgba(64, 158, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 36px;
        cursor: pointer;

        &::before {
          font-family: "iconfont";
          content: "\e6c4";
          font-size: 18px;
          color: rgba(64, 158, 255, 1);
          margin-right: 7px;
        }
      }
    }
  }

  .logCon {
    border: 1px solid #e4e7ed;
    padding: 15px;
    position: relative;

    .clickBtn {
      color: rgba(64, 158, 255, 1);
      position: absolute;
      right: 15px;
      bottom: 10px;
      padding: 5px;
      cursor: pointer;
    }

    .update {
      padding: 18px 0 0;
      display: flex;
      justify-content: flex-start;
      line-height: 30px;

      .title {
        width: 42px;
        font-weight: 400;
        color: #909399;
      }

      .content {
        width: 90%;

        .ntit {
          width: 105px;
          color: rgba(103, 194, 58, 1);
          padding-right: 60px;
          position: relative;
          padding-left: 12px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 11px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: rgba(103, 194, 58, 1);
          }
        }

        .newly,
        .modify,
        .modifyDel {
          display: flex;
          justify-content: flex-start;
          width: 96%;
          padding-bottom: 5px;

          .con {
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 32px;
            transition: height 0.3s;
            -moz-transition: height 0.3s;
            /* Firefox 4 */
            -webkit-transition: height 0.3s;
            /* Safari and Chrome */
            -o-transition: height 0.3s;
            /* Opera */
          }

          dl {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-bottom: 2px;

            dt {
              width: 75%;
            }

            dd {
              width: 25%;

              span {
                display: inline-block;
                line-height: 28px;
                padding: 0 15px;
                background: #fef0f0;
                border-radius: 4px;
                border: 1px solid #fbc4c4;
                color: rgba(245, 108, 108, 1);
                font-size: 12px;
                margin-right: 5px;
              }
            }
          }
        }

        .modify {
          .ntit {
            color: rgba(230, 162, 60, 1);

            &::before {
              background-color: rgba(230, 162, 60, 1);
            }
          }
        }

        .modifyDel {
          .ntit {
            color: rgba(245, 108, 108, 1);

            &::before {
              background-color: rgba(245, 108, 108, 1);
            }
          }
        }
      }
    }
  }

  .explain {
    display: flex;
    justify-content: flex-start;

    dt {
      width: 45px;
      font-weight: 400;
      color: #909399;
      line-height: 20px;
    }

    dd {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      height: 20px;
      transition: height 0.3s;
      -moz-transition: height 0.3s;
      /* Firefox 4 */
      -webkit-transition: height 0.3s;
      /* Safari and Chrome */
      -o-transition: height 0.3s;

      /* Opera */
      p {
        position: relative;
        padding-left: 10px;

        &::before {
          content: "";
          width: 0;
          height: 0;
          border: 3px solid darkgray;
          border-radius: 50%;
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 7px;
        }
      }
    }
  }

  .list {
    padding-bottom: 30px;
    transition: height 0.3s;
    -moz-transition: height 0.3s;
    /* Firefox 4 */
    -webkit-transition: height 0.3s;
    /* Safari and Chrome */
    -o-transition: height 0.3s;
    /* Opera */

    &.hover {
      .update {
        .content {

          .newly,
          .modify,
          .modifyDel {
            .con {
              height: auto;
            }
          }
        }
      }

      .explain {
        dd {
          height: auto;
        }
      }
    }
  }
}

.loadingBottom {
  width: 100%;
  text-align: center;
  padding: 40px 0;
  line-height: 40px;
  font-size: 18px;

  img {
    vertical-align: top;
    margin-right: 20px;
  }
}
</style>
